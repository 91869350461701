import React from 'react';

function App() {
  return (
    <div className="min-h-screen bg-black">
      <section className="h-screen flex flex-col items-center justify-center">
        {/* Main artwork */}
        <div className="relative w-[375px] mb-8 pt-12">
          <a 
            href="https://www.britannica.com/topic/Mona-Lisa-painting"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img 
              src={process.env.PUBLIC_URL + '/monalisa.png'} 
              alt="Mona Lisa"
              className="w-full"
            />
          </a>
        </div>

        {/* Description */}
        <div className="max-w-[600px] text-white text-center mb-8 px-4">
          <p className="text-gray-300 leading-relaxed">
            The "Mona Lisa," painted by Leonardo da Vinci around 1503-1506, is a half-length portrait that has become one of the most recognized works of art in the world. Known for her enigmatic smile and serene expression, the subject, possibly Lisa Gherardini, sits with her hands gently folded in her lap. The painting's background features winding roads and a bridge, creating a mysterious and atmospheric setting. Housed in the Louvre Museum in Paris, it exemplifies Renaissance art through its mastery of light, composition, and the sfumato technique for soft transitions.
          </p>
        </div>

        {/* Social Icons */}
        <div className="flex items-center justify-center space-x-8">
          {/* DexScreener */}
          <a 
            href="https://dexscreener.com/solana/hzfj5qt6qexbfcw9mux3dcp8zb79phgi9svtlo5fukhg" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="w-8 h-8 flex items-center justify-center"
          >
            <img
              src={process.env.PUBLIC_URL + '/dexlogo.png'}
              alt="Dexscreener"
              width="24"
              height="24"
              className="w-6 h-6"
              loading="lazy"
              decoding="async"
            />
          </a>
          {/* DexTools */}
          <a 
            href="https://www.dextools.io/app/en/solana/pair-explorer/Hzfj5qT6QEXBFcw9MUx3dCP8zB79pHgi9SvtLo5fuKHg?t=1732988459936"
            target="_blank"
            rel="noopener noreferrer"
            className="w-8 h-8 flex items-center justify-center"
          >
            <img
              src={process.env.PUBLIC_URL + '/DEXTools.png'}
              alt="DexTools"
              width="24"
              height="24"
              className="w-6 h-6"
              loading="lazy"
              decoding="async"
            />
          </a>
          {/* X (Twitter) */}
          <a 
            href="https://x.com/MonaLisaCTO" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="w-8 h-8 flex items-center justify-center text-white hover:text-gray-300"
          >
            <svg viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
            </svg>
          </a>
        </div>

        {/* Down arrow */}
        <button 
          className="mt-4 w-8 h-8 text-white hover:text-gray-300"
          onClick={() => {
            window.scrollTo({
              top: document.documentElement.scrollHeight,
              behavior: 'smooth'
            });
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
          </svg>
        </button>
      </section>

      {/* Instructions section */}
      <section className="min-h-screen bg-black flex flex-col items-center py-20">
        <h1 className="text-white text-4xl font-bold mb-2">Join the movement</h1>
        <p className="text-gray-400 mb-16">How to buy $MONA</p>

        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
          {/* Step 1 */}
          <div className="bg-[#1c1c1c] rounded-lg p-8 relative">
            <div className="absolute top-4 left-4 w-8 h-8 rounded-full bg-white flex items-center justify-center text-black font-bold">
              1
            </div>
            <h2 className="text-white text-xl font-bold mt-8 mb-4">Create a Phantom Wallet</h2>
            <p className="text-gray-400 mb-8">Download Phantom wallet from phantom.app and create a new wallet to store your SOL and tokens.</p>
            <a 
              href="https://phantom.app/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="block w-full bg-white text-black py-3 rounded-lg text-center hover:bg-gray-100 transition-colors"
            >
              Download Phantom
            </a>
          </div>

          {/* Step 2 */}
          <div className="bg-[#1c1c1c] rounded-lg p-8 relative">
            <div className="absolute top-4 left-4 w-8 h-8 rounded-full bg-white flex items-center justify-center text-black font-bold">
              2
            </div>
            <h2 className="text-white text-xl font-bold mt-8 mb-4">Buy SOL</h2>
            <p className="text-gray-400 mb-8">Purchase SOL directly on Phantom, or from an exchange like Binance or Coinbase and transfer it to your Phantom wallet.</p>
            <a 
              href="https://phantom.app/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="block w-full bg-white text-black py-3 rounded-lg text-center hover:bg-gray-100 transition-colors"
            >
              Buy SOL
            </a>
          </div>

          {/* Step 3 */}
          <div className="bg-[#1c1c1c] rounded-lg p-8 relative">
            <div className="absolute top-4 left-4 w-8 h-8 rounded-full bg-white flex items-center justify-center text-black font-bold">
              3
            </div>
            <h2 className="text-white text-xl font-bold mt-8 mb-4">Connect to Raydium and buy $MONA</h2>
            <p className="text-gray-400 mb-8">Visit the Raydium.io MONA page, click "I understand, confirm", then connect your Phantom wallet by clicking the "Connect Wallet" button. When Phantom prompts you for a wallet signature, click "Sign". Then enter the amount of tokens you want to buy and click "Swap" to buy.</p>
            <a 
              href="https://raydium.io/swap/?inputMint=sol&outputMint=DjYrvmmY6ojf2f62TckYxBtCCHCJNbr9A4EXFwH3pump" 
              target="_blank" 
              rel="noopener noreferrer"
              className="block w-full bg-white text-black py-3 rounded-lg text-center hover:bg-gray-100 transition-colors"
            >
              Go to Raydium
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
